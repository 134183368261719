'use strict'

const _ = require('lodash')

const fixBreakpointRelationItem = item => {
    if (item.type === 'BreakpointRelation') {
        item.type = 'VariantRelation'
        item.to = item.ref
        delete item.ref
        if (item.breakpoint) {
            item.variants = [item.breakpoint]
            delete item.breakpoint
        }
    }
}
/**
 * Fixes:
 * - move all items from breakpoints_data to variants_data
 * - change relationItem type from BreakpointRelation to VariantRelation in theme_data and layout_data
 * - change condition property for BreakpointRelation items from breakpoint to variants in theme_data and layout_data
 * - change ref property for BreakpointRelation items ('ref' -> 'to') in theme_data and layout_data
 * */
const fixPage = page => {
    const data = page.data
    if (!data.breakpoints_data) {
        return // don't need to run if data already migrated
    }
    if (data.breakpoints_data) {
        data.variants_data = data.breakpoints_data
        delete data.breakpoints_data
    }
    if (data.layout_data) {
        data.layout_data = _.mapValues(data.layout_data, item => {
            fixBreakpointRelationItem(item)
            return item
        })
    }
    if (data.theme_data) {
        data.theme_data = _.mapValues(data.theme_data, item => {
            fixBreakpointRelationItem(item)
            return item
        })
    }
    return data
}

/**
 * @exports utils/dataFixer/plugins/migrateResponsiveToVariantsModels
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewer, rendererModel, magicObject) {
        const {isExperimentOpen, isViewerMode} = magicObject
        const isMigrateAbsoluteLayoutToDataMaps = isExperimentOpen('bv_migrateResponsiveToVariantsModels')
        if (!isMigrateAbsoluteLayoutToDataMaps || !isViewerMode) {
            return
        }
        
        fixPage(pageJson)
    }
}
